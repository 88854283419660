const originalFunctionKey = Symbol();
const curriedFunctionKey = Symbol();
const partialArgsKey = Symbol();
const nameKey = Symbol();
function curry(f, name) {
  function curried(...args) {
    if (args.length >= f.length) {
      return f(...args);
    } else {
      const f2 = (...moreArgs) => curried(...args, ...moreArgs);
      f2[originalFunctionKey] = f;
      f2[curriedFunctionKey] = curried;
      f2[partialArgsKey] = args;
      f2[nameKey] = curried[nameKey];
      return f2;
    }
  }
  curried[originalFunctionKey] = f;
  curried[curriedFunctionKey] = curried;
  curried[partialArgsKey] = [];
  curried[nameKey] = name || functionName(f);
  return curried;
}
function originalFunction(f) {
  return f[originalFunctionKey];
}
function curriedFunction(f) {
  return f[curriedFunctionKey];
}
function partialArgs(f) {
  return f[partialArgsKey] || [];
}
function functionName(f) {
  return f[nameKey] || f.name;
}

function createSuite() {
  const testCases = [];
  return { test: test2, getAllTests: getAllTests2 };
  function test2(subject, definitions) {
    testCases.push(...Object.entries(definitions).map(([behavior, fn]) => TestCase(subject, behavior, fn)));
  }
  function getAllTests2() {
    return testCases;
  }
}
function expect(subject, expectation, ...args) {
  const pass = expectation(...args, subject);
  if (typeof pass === "function") {
    throw new Error("The matcher function `" + prettyFunctionName(pass) + "` returned a function instead of a boolean. You might need to pass another argument to it.");
  }
  if (!pass) {
    throw new ExpectationFailure([subject, expectation, ...args]);
  }
}
function TestCase(subject, scenario, fn) {
  return { subject, scenario, fn };
}
class ExpectationFailure extends Error {
  constructor(expectArgs) {
    super("Expectation failed");
    this.expectArgs = expectArgs;
  }
}

function lastOf(a) {
  return a[a.length - 1];
}
function firstOf(a) {
  return a[0];
}

const which = curry(function(predicate, x) {
  return predicate(x);
}, "which");
const equals = curry(function(a, b) {
  if (isCustomMatcher(a)) {
    return a(b);
  }
  if (Array.isArray(a) && Array.isArray(b)) {
    return a.length === b.length && a.every((_, i) => equals(a[i], b[i]));
  }
  if (a instanceof Function && b instanceof Function) {
    if (originalFunction(a) && originalFunction(a) === originalFunction(b)) {
      return equals(partialArgs(a), partialArgs(b));
    }
    return a === b;
  }
  if (a instanceof Date && b instanceof Date) {
    return a.toISOString() === b.toISOString();
  }
  if (a instanceof Set && b instanceof Set) {
    return a.size === b.size && [...a.values()].every((v) => b.has(v));
  }
  if (isObject(a) && isObject(b)) {
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);
    return aKeys.length === bKeys.length && aKeys.every((k) => equals(a[k], b[k])) && a.__proto__?.constructor === b.__proto__?.constructor;
  }
  return a === b;
}, "equals");
const is = curry(function(a, b) {
  return a === b;
}, "is");
const not = curry(function(predicate, subject, ...args) {
  return !predicate(subject, ...args);
}, "not");
const isBlank = curry(function(s) {
  return /^\s*$/.test(s);
}, "isBlank");
function isObject(x) {
  return !!x && typeof x === "object";
}
function isCustomMatcher(f) {
  return f instanceof Function && curriedFunction(f) === which && partialArgs(f).length === 1;
}

function prettyFunctionName(f) {
  return functionName(f) || "<function>";
}
function pretty(x) {
  const stack = [];
  return _pretty(x);
  function _pretty(x2) {
    if (x2 === null)
      return "null";
    if (typeof x2 === "function")
      return preventInfiniteLoop(x2, prettyFunction);
    if (typeof x2 === "string")
      return quote(x2);
    if (typeof x2 === "bigint")
      return `${x2}n`;
    if (Array.isArray(x2))
      return preventInfiniteLoop(x2, prettyArray);
    if (x2 instanceof Date)
      return `Date(${x2.toISOString().replace("T", " ").replace("Z", " UTC")})`;
    if (x2 instanceof RegExp)
      return String(x2);
    if (x2 instanceof Error)
      return `${prettyConstructor(x2)}(${quote(x2.message)})`;
    if (x2 instanceof Set)
      return preventInfiniteLoop(x2, prettySet);
    if (typeof x2 === "object") {
      const constructor = x2?.__proto__?.constructor;
      if (constructor === Object || !constructor)
        return preventInfiniteLoop(x2, prettyObject);
      else
        return `${prettyConstructor(x2)} ${preventInfiniteLoop(x2, prettyObject)}`;
    }
    return String(x2);
  }
  function preventInfiniteLoop(x2, cb) {
    if (stack.indexOf(x2) > -1)
      return "<circular reference>";
    stack.push(x2);
    const result = cb(x2);
    stack.pop();
    return result;
  }
  function prettyFunction(f) {
    const args = partialArgs(f).map(_pretty);
    const name = prettyFunctionName(f);
    if (!args.length)
      return name;
    return formatStructure(name + "(", args, ",", ")");
  }
  function prettyArray(a) {
    return formatStructure("[", a.map(_pretty), ",", "]");
  }
  function prettyObject(x2) {
    const innards = Object.entries(x2).map(([k, v]) => `${prettyKey(k)}: ${_pretty(v)}`);
    return formatStructure("{", innards, ",", "}");
  }
  function prettySet(x2) {
    const innards = [...x2.values()].map(_pretty);
    return formatStructure("Set {", innards, ",", "}");
  }
}
function prettyKey(k) {
  return /^[a-zA-Z0-9_$]+$/.test(k) ? k : quote(k);
}
function prettyConstructor(obj) {
  return prettyFunctionName(obj.__proto__.constructor);
}
function quote(s) {
  return '"' + String(s).replace(/\\/g, "\\\\").replace(/\n/g, "\\n").replace(/\t/g, "\\t").replace(/"/g, '\\"').replace(/[\x00-\x1f\x7f]/g, hexEscape) + '"';
}
function hexEscape(c) {
  const hex = c.charCodeAt(0).toString(16);
  return "\\x" + (hex.length < 2 ? "0" + hex : hex);
}
function indent(level, s) {
  return s.split("\n").map((l) => !l ? l : prepend(repeat(level, " "))(l)).join("\n");
}
function repeat(n, s) {
  return Array(n + 1).join(s);
}
const prepend = (prefix) => (s) => prefix + s;
const removePrefix = curry(function removePrefix2(prefix, s) {
  const hasPrefix = s.slice(0, prefix.length) === prefix;
  return hasPrefix ? s.slice(prefix.length) : s;
});
function lines(s) {
  return String(s).split(/\r?\n/);
}
function trimMargin(s) {
  const lns = lines(s);
  if (isBlank(firstOf(lns)))
    lns.shift();
  if (isBlank(lastOf(lns)))
    lns.pop();
  const initialIndent = /^[ \t]*/.exec(firstOf(lns))[0];
  return lns.map(removePrefix(initialIndent)).join("\n");
}
function formatStructure(prefix, innards, delim, suffix) {
  if (innards.length < 2) {
    return prefix + innards.join("") + suffix;
  } else {
    return prefix + "\n" + indent(2, innards.join(delim + "\n")) + "\n" + suffix;
  }
}

const isExpectationFailure = curry(function isExpectationFailure2(args, error) {
  return error instanceof ExpectationFailure && equals(args, error.expectArgs);
});

const basePassingTest = Object.freeze({
  test: {
    subject: "a thing",
    scenario: "does something",
    fn() {
    }
  },
  error: void 0,
  instrumentLog: []
});

export { ExpectationFailure as E, is as a, equals as b, createSuite as c, expect as e, formatStructure as f, indent as i, not as n, pretty as p, trimMargin as t };

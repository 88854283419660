import { i as indent, f as formatStructure, p as pretty, E as ExpectationFailure, t as trimMargin, c as createSuite } from '../common/plain-text-test-formatter-f65fe532.js';
export { c as createSuite, b as equals, e as expect, a as is, n as not } from '../common/plain-text-test-formatter-f65fe532.js';

async function runTests(tests) {
  const results = [];
  for (const test of tests) {
    const error = await errorFrom(test.fn);
    const instrumentLog = debugLogs.map((args) => ({ type: "debug", args }));
    debugLogs.length = 0;
    results.push({
      test,
      error,
      instrumentLog
    });
  }
  return { results };
}
function errorFrom(f) {
  let caught;
  try {
    const result2 = f();
    if (result2 instanceof Promise) {
      return new Promise((resolve) => {
        result2.then(() => resolve()).catch(resolve);
      });
    }
  } catch (e) {
    caught = e;
  }
  return Promise.resolve(caught);
}
const debugLogs = [];

const blankLine = "\n\n";
function formatTestResultsAsText({ results }) {
  let anyErrors = false;
  let anyInstrumentation = false;
  let resultsNeedingAttention = [];
  for (const r of results) {
    let needsAttention = false;
    if (r.error) {
      needsAttention = anyErrors = true;
    }
    if (r.instrumentLog.length) {
      needsAttention = anyInstrumentation = true;
    }
    if (needsAttention) {
      resultsNeedingAttention.push(r);
    }
  }
  if (anyErrors) {
    return suiteFailed(resultsNeedingAttention);
  }
  if (anyInstrumentation) {
    return suitePassedWithInstrumentation(results.length, resultsNeedingAttention);
  }
  return suitePassed(results.length);
}
function suiteFailed(failures) {
  return failures.map(formatFailure).join(blankLine) + blankLine + "Tests failed.";
}
function suitePassed(numPassed) {
  switch (numPassed) {
    case 0:
      return "No tests to run.";
    case 1:
      return "One test ran, and found no issues.";
    default:
      return `${numPassed} tests ran, and found no issues.`;
  }
}
function suitePassedWithInstrumentation(numPassed, resultsWithLogs) {
  return resultsWithLogs.map(formatFailure).join(blankLine) + blankLine + countPasses(numPassed) + ", but debugging instrumentation is present.\nRemove it before shipping.";
}
function countPasses(n) {
  switch (n) {
    case 1:
      return "The test passed";
    case 2:
      return "Both tests passed";
    default:
      return `All ${n} tests passed`;
  }
}
function formatFailure({ test, error, instrumentLog }) {
  const title = test.subject + " " + test.scenario;
  const sections = [title];
  if (instrumentLog.length)
    sections.push(indent(2, formatDebugLog(instrumentLog)));
  if (error)
    sections.push(indent(2, formatError(error)));
  return sections.join("\n");
}
function formatError(error) {
  return error instanceof ExpectationFailure ? formatExpectationFailure(error) : formatException(error);
}
function formatDebugLog(log) {
  return log.map(({ args }) => formatFunctionCall("debug", args)).join("");
}
function formatExpectationFailure(error) {
  return formatFunctionCall("expect", error.expectArgs);
}
function formatException(error) {
  return pretty(error) + " thrown" + indent(2, simplifyStacktrace(error.stack));
}
function formatFunctionCall(name, args) {
  return formatStructure(name + "(", args.map(pretty), ",", ")");
}
function simplifyStacktrace(stack) {
  if (!stack)
    return "";
  const lines = trimMargin(stack).split("\n");
  return "\n" + lines.slice(0, indexOfFirstIrrelevantStackFrame(lines)).map((line) => line.replace(/(file:\/\/|http:\/\/[^/]*)/, "").replace(/^([^@]*)@(.*)$/, "at $1 ($2)")).join("\n");
}
function indexOfFirstIrrelevantStackFrame(lines) {
  const i = lines.findIndex((l) => l.includes("errorFrom"));
  if (i === -1)
    return lines.length;
  else
    return i;
}

const suite = createSuite();
const { getAllTests } = suite;
function test(...args) {
}

export { formatTestResultsAsText, getAllTests, runTests, test };
